import React, { useEffect, useState } from "react";
import { images } from "../../data/url";
// import { loadingSound } from "../../data/sounds/loading";

import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";
import { AppColorScheme } from "./AppSuspense";
import { Background } from "./Background";
import i18n from "@/utils/i18n";
import { LOCALES } from "../../../../locales/types";

type KotbreadPanelProps = {
  onEnd?: () => void;
  colorScheme: AppColorScheme;
  progress: number;
};

const logoScale = keyframes`
  from {
    scale: 0.8;
    opacity: 0;
  }
  50% {
    scale: 1.1;
  }
  to {
    scale: 1;
    opacity: 1;
  }
`;

const KotBreadLogo = styled.img`
  width: 100%;
  max-width: 260px;
  animation: ${logoScale} 1s ease-out;
`;

const Progress = styled.div<{ colorScheme: AppColorScheme }>`
  position: absolute;
  bottom: 64px;
  width: fit-content;
  text-align: center;
  font-size: 18px;
  color: ${({ colorScheme }) => (colorScheme === "dark" ? "white" : "black")};
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
`;

// const setLogoTimeout = () =>
//   new Promise((resolve) => setTimeout(resolve, 1000));
const KotbreadPanel: React.FC<KotbreadPanelProps> = ({
  onEnd,
  colorScheme,
  progress,
}) => {
  const [percent, setPercent] = useState(1);

  // Проигрывает звуковой эффект при запуске
  useEffect(() => {
    const updateProgress = setInterval(() => {
      setPercent((percent) =>
        Math.floor(
          percent >= 99
            ? 99
            : percent >= 80
            ? percent + ((100 - percent) / 10) * Math.random() + 0.8
            : percent + 15 * Math.random()
        )
      );
    }, Math.random() * 500 + 100);
    // loadingSound.play();
    // loadingSound.once("end", () => {
    //   if (onEnd) onEnd();
    // });
    return () => clearInterval(updateProgress);
  }, []);

  const innerString = `${i18n.t(LOCALES.Loading)}: ${progress || percent}%`;

  return (
    <Background colorScheme={colorScheme}>
      <KotBreadLogo
        src={
          colorScheme === "dark"
            ? images.kotbreadLogo.light
            : images.kotbreadLogo.purple
        }
        alt="Логотип КотBread"
      />
      <Progress colorScheme={colorScheme}>{innerString}</Progress>
    </Background>
  );
};

export default React.memo(KotbreadPanel);
