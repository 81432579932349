// Import React
import React from "react";
import ReactDOM from "react-dom";
// Import VK libraries
import "@vkontakte/vkui/dist/vkui.css";
import bridge, { BannerAdLayoutType } from "@vkontakte/vk-bridge";
// import { useFirstLocation } from "./utils/useFirstLocation"; // Нужно для сохранения стартовой локации
// Import Redux
import { Provider } from "react-redux";
import store from "./store";

// Import application
import "./main.css";
// import eruda from "eruda";
import AdsContoller from "./utils/adsController";
// import * as Sentry from "@sentry/react";
import { IS_OK, IS_PROD, IS_TG, IS_VK, SENTRY_DSN } from "./config/constants";
import "./utils/i18n";
import { init } from "@tma.js/sdk";
import PreAppSuspense from "./components/AppSuspense/PreAppSuspense";

// if (import.meta.env.VITE_ERUDA === "true") {
//   const el = document.createElement("div");
//   document.body.appendChild(el);
//   eruda.init({
//     container: el,
//   });
// }
// TODO: мб это не надо, потом проверить
if (IS_OK) {
  const fapi = FAPI.Util.getRequestParameters();
  FAPI.init(fapi["api_server"], fapi["apiconnection"]);
}

if (IS_VK) bridge.send("VKWebAppInit", {});

// Скрываем неверно показанную рекламу

// bridge.subscribe(async (data) => {
//   console.info(data.detail.type);
//   if (data.detail.type === "VKWebAppViewHide") {
//     await request({ method: "user/analytics", auth: true });
//   }
// });
AdsContoller.showBanner({ layout_type: BannerAdLayoutType.RESIZE });

if (window.tma !== undefined) {
  try {
    if (window?.Telegram?.WebApp?.ready) window?.Telegram?.WebApp?.ready();
    window.tma = init();
    window.tma.viewport.expand();
    window.tma.viewport.stableHeight;
    window.tma.closingBehavior.enableConfirmation();
    console.log("swipe0");
    // const [swipeBehavior] = initSwipeBehavior();
    // swipeBehavior.disableVerticalSwipe();
    // console.log("swipe1");
    // console.log("swipe", swipeBehavior.isVerticalSwipeEnabled);
  } catch {
    console.warn("@tma.js/sdk not supported");
  }
}
// try {
//   Sentry.setUser({ username: IS_TG ? "t.me/" : "vk.com/id" + getId() });
//   Sentry.init({
//     dsn: SENTRY_DSN,
//     integrations: [Sentry.browserTracingIntegration()],
//     enabled: IS_PROD,
//     // Performance Monitoring
//     tracesSampleRate: 0.5, //  Capture 50% of the transactions
//   });
// } catch (e) {
//   console.error("Cannot initialize Sentry:", e);
// }

// // Использование динамического компрессора с Web Audio API
// var audioCtx = Howler.ctx;
// var compressor = audioCtx.createDynamicsCompressor();

// // Настраиваем компрессор
// compressor.threshold.setValueAtTime(-30, audioCtx.currentTime);
// compressor.knee.setValueAtTime(40, audioCtx.currentTime);
// compressor.ratio.setValueAtTime(12, audioCtx.currentTime);
// compressor.attack.setValueAtTime(0, audioCtx.currentTime);
// compressor.release.setValueAtTime(0.25, audioCtx.currentTime);

// // Подключаем компрессор к мастер-гейну Howler
// Howler.masterGain.connect(compressor);
// compressor.connect(audioCtx.destination);
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PreAppSuspense />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
