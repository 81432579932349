import React, { lazy, Suspense, useCallback, useEffect, useState } from "react";
import styled from "@emotion/styled";
import KotbreadPanel from "./KotbreadPanel";
import bridge from "@vkontakte/vk-bridge";
import { IS_OK, IS_VK } from "@/config/constants";

const AppSuspense = lazy(() => import("./AppSuspense"));

const LoadIndicatorStyled = styled.div`
  display: none;
`;

type LoadIndicatorProps = {
  onLoad: () => void;
};

const LoadIndicator: React.FC<LoadIndicatorProps> = ({ onLoad }) => {
  useEffect(() => {
    return () => onLoad();
  }, []);
  return <LoadIndicatorStyled />;
};

export type AppColorScheme = "light" | "dark";

const PreAppSuspense = () => {
  console.info("hello");
  const [isLoaded, setIsLoaded] = useState(false);

  const [colorScheme, setColorScheme] = useState<AppColorScheme>("dark");

  useEffect(() => {
    if (IS_VK) {
      bridge.subscribe(({ detail }) => {
        if (detail.type === "VKWebAppUpdateConfig") {
          setColorScheme(
            detail.data.scheme.includes("light") ? "light" : "dark"
          );
        }
      });
    }
    if (IS_OK) {
      setColorScheme("light");
    }
    bridge.subscribe(({ detail }) => {
      if (detail.type === "VKWebAppUpdateConfig") {
        setColorScheme(
          detail?.data?.scheme?.includes("light") ? "light" : "dark"
        );
      }
    });
  }, []);
  return (
    <>
      <Suspense fallback={<LoadIndicator onLoad={() => setIsLoaded(true)} />}>
        <AppSuspense />
      </Suspense>
      {!isLoaded && <KotbreadPanel colorScheme={colorScheme} progress={0} />}
    </>
  );
};

export default React.memo(PreAppSuspense);
